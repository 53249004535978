import React from "react";

import PollenSidebarCta from "@components/PollenSidebarCta";
import usePollenOptionsStore from "@sp-state/usePollenOptionsStore";

const UpperSidebarCtaContainer: React.FC = props => {
  const { pollenUpperSidebarCta } = usePollenOptionsStore(state => state);

  return (
    <div style={{ marginBottom: 40 }}>
      {pollenUpperSidebarCta && <PollenSidebarCta {...pollenUpperSidebarCta} />}
    </div>
  );
};

export default UpperSidebarCtaContainer;
