import React, { useState } from "react";
import { navigate } from "gatsby";
import BlogHeroHome from "./BlogHeroHome/BlogHeroHome";
import PostItemSimpleHome from "./PostItemSimpleHome/PostItemSimpleHome";
import TheLatestPosts from "./TheLatestPosts/TheLatestPosts";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { PollenPost } from "../../../assets/Types";
import styles from "./BlogGridHome.module.scss";
import UpperSidebarCtaContainer from "@containers/UpperSidebarCtaContainer";

const BlogGridHome: React.FC<{ posts: PollenPost[] }> = ({ posts }) => {
  const [search, setSearch] = useState("");
  const heroPost = posts[0];
  const postsItemSimple = posts.slice(1, 3);
  const latestPosts = posts.slice(3);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/search?term=${search}`);
  };

  return (
    <div className={styles.blogGrid}>
      <div className={styles.bcard}>
        <BlogHeroHome blogHero={heroPost} hasNoBorder />
        {postsItemSimple?.map((post, index) => (
          <PostItemSimpleHome key={index} post={post} isHome />
        ))}
      </div>

      <div className={styles.bside}>
        <SearchInput
          value={search}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
        <UpperSidebarCtaContainer />
        {latestPosts.length > 0 && <TheLatestPosts latestPosts={latestPosts} />}
      </div>
    </div>
  );
};

export default BlogGridHome;
