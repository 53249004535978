import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styles from "./PostItemSimpleHome.module.scss";

type PostItemSimpleHomeProps = {
  post: Pick<
    Queries.WpPost,
    "author" | "featuredImage" | "title" | "uri" | "excerpt"
  >;
  showImage?: boolean;
  isHome?: boolean;
};

const PostItemSimpleHome: React.FC<PostItemSimpleHomeProps> = ({
  post,
  showImage = true,
  isHome = false,
}) => {
  const gatsbyImageData = post.featuredImage?.node.gatsbyImage;
  const postItemClasses = isHome
    ? `${styles.postItemSimple} ${styles.home}`
    : styles.postItemSimple;

  return (
    <div className={postItemClasses}>
      <div className={styles.postItemSimpleLink}>
        {showImage && (
          <Link to={post.uri!}>
            <GatsbyImage
              className={styles.postItemSimpleLinkImg}
              image={gatsbyImageData!}
              alt={post.featuredImage?.node.altText || ""}
            />
          </Link>
        )}
        <div>
          <Link to={post.uri!}>
            <h3 className={styles.postTitle}>{post.title}</h3>
          </Link>
          <div className={styles.postAuthorName}>
            <Link
              to={post.author?.node.uri!}
              title={`Posts by ${post.author?.node.name}`}
              rel="author"
              dangerouslySetInnerHTML={{ __html: post.author?.node.name! }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostItemSimpleHome;
