import React, { memo } from "react";
import PostItem from "./PostItem/PostItem";
import { ProductAdNode } from "../../../assets/Types";

import styles from "./BlogCards.module.scss";

type BlogCardsProps = {
  posts: Queries.WpPostConnection["nodes"];
  productAd?: ProductAdNode;
};

const BlogCards: React.FC<BlogCardsProps> = ({ posts, productAd }) => (
  <div className={styles.blogCards}>
    {posts.map((post, index) => (
      <PostItem key={index} post={post} />
    ))}
  </div>
);

export default memo(BlogCards);
