import React, { memo } from "react";

import { ProductAdNode } from "../../assets/Types";
import BlogCards from "./BlogCards/BlogCards";
import BlogGridHome from "./BlogGridHome/BlogGridHome";
import MoreStories from "./MoreStories/MoreStories";
import PollenSpBannerContainer from "@containers/PollenSpBannerContainer";

import styles from "./PollenSectionContainer.module.scss";

type PollenSectionContainerProps = {
  posts: Queries.WpPostConnection["nodes"];
  productAd: ProductAdNode;
};

const PollenSectionContainer: React.FC<PollenSectionContainerProps> = ({
  posts,
  productAd,
}) => {
  const blogsGridHome = posts.slice(0, 7);
  const blogCards = posts.slice(7, 13);
  const blogMoreStories = posts.slice(-5);

  const hasBlogCards = blogCards.length > 0;
  const hasMoreStories = blogMoreStories.length > 0;

  return (
    <section
      data-testid="section:PollenSectionContainer"
      className={`${styles.pollenContainer} ${styles.pollenContainerPad}`}
    >
      <div className="full-width">
        <BlogGridHome posts={blogsGridHome} />
      </div>

      <PollenSpBannerContainer />

      <div className="full-width">
        {hasBlogCards && <BlogCards posts={blogCards} productAd={productAd} />}
      </div>
      <div className="full-width">
        {hasMoreStories && <MoreStories posts={blogMoreStories} />}
      </div>
    </section>
  );
};

export default memo(PollenSectionContainer);
