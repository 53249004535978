import React from "react";

import styles from "./PollenSpBannerContainer.module.scss";

import usePollenOptionsStore from "@sp-state/usePollenOptionsStore";
import PollenSpBanner from "@components/PollenSpBanner";

const PollenSpBannerContainer: React.FC = props => {
  const { pollenSpBanner } = usePollenOptionsStore(state => state);

  return (
    <div className={styles.pollenSpBanner}>
      <div className="full-width">
        {pollenSpBanner && <PollenSpBanner {...pollenSpBanner} />}
      </div>
    </div>
  );
};

export default PollenSpBannerContainer;
