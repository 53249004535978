import React from "react";
import PostItemSimpleHome from "../PostItemSimpleHome/PostItemSimpleHome";
import { PollenPost } from "../../../../assets/Types";
import styles from "./TheLatestPosts.module.scss";

const TheLatestPosts: React.FC<{ latestPosts: PollenPost[] }> = ({
  latestPosts,
}) => {
  return (
    <div className={styles.latestContainer}>
      <h2>The Latest</h2>
      {latestPosts.map((post, index) => (
        <PostItemSimpleHome key={index} post={post} showImage={false} />
      ))}
    </div>
  );
};

export default TheLatestPosts;
