import React from "react";
import { Link } from "gatsby";

import styles from "./PostsNavSection.module.scss";

type IPostsNavSection = {
  hasNext?: boolean;
  hasPrevious?: boolean;
  currentPage: number;
  parentUrl: string;
};

const PostsNavSection: React.FC<IPostsNavSection> = ({
  hasNext,
  hasPrevious,
  currentPage,
  parentUrl,
}) => {
  const nextLink = `${parentUrl}page/${currentPage + 1}`;
  const previousLink =
    currentPage > 2 ? `${parentUrl}page/${currentPage - 1}` : `${parentUrl}`;

  return (
    <nav className={`${styles.navLinks} apple-font-family`}>
      {hasPrevious && (
        <div className={styles.navPrevious}>
          <Link to={previousLink}>PREVIOUS</Link>
        </div>
      )}

      {hasNext && (
        <div className={styles.navNext}>
          <Link to={nextLink}>MORE STORIES</Link>
        </div>
      )}
    </nav>
  );
};

export default PostsNavSection;
