import React from "react";

import { ModalTrigger } from "@components/Buttons";

import styles from "./PollenSpBanner.module.scss";

const PollenSpBanner: React.FC<
  Queries.builder_PollenOptionsData["pollenSpBanner"]
> = ({ headline, subcopy, image, ctaCopy }) => {
  return (
    <section className={styles.pollenSpBanner} data-testid="pollen-sp-banner">
      <div className={styles.mainContent}>
        <figure className={styles.bannerIcon}>
          <img
            alt="logo"
            data-testid="pollen-sp-banner-icon"
            src="/images/pollen-sp-logo.png"
          />
        </figure>

        <h2
          className={styles.headline}
          dangerouslySetInnerHTML={{ __html: headline }}
          data-testid="pollen-sp-banner-headline"
        />
        <p className={styles.subcopy} data-testid="pollen-sp-banner-subcopy">
          {subcopy}
        </p>

        <ModalTrigger
          className={styles.cta}
          data-testid="pollen-sp-banner-cta"
          modalTrigger="pollen-lead"
        >
          {ctaCopy}

          <svg data-testid="button:icon" className={styles.btnIcon}>
            <use href="/icons/right-arrow.svg#right-arrow"></use>
          </svg>
        </ModalTrigger>
      </div>

      <figure className={styles.bannerImage}>
        <img src={image} alt="people img" data-testid="pollen-sp-banner-img" />
      </figure>
    </section>
  );
};

export default PollenSpBanner;
