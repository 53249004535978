import React from "react";
import { PageProps, graphql } from "gatsby";

import PollenSectionContainer from "../../containers/PollenSectionContainer/PollenSectionContainer";
import PostsNavSection from "../../containers/PostsNavSection/PostsNavSection";
import { getRandomInt } from "../../utils/getRandomInt";
import { ProductAdQuery } from "../../assets/StaticQueries/ProductAd.query";
import Seo from "gatsby-plugin-wpgraphql-seo";
import DefaultPollenLayout from "../../layouts/DefaultPollenLayout";

type DataProps = {
  allWpPost: {
    nodes: Queries.WpPostConnection["nodes"];
  };
  wp: any;
};

type PageContextProps = {
  currentPage: number;
  limit: number;
  numPages: number;
  skip: number;
};

const DefaultPage: React.FC<PageProps<DataProps, PageContextProps>> = ({
  data,
  pageContext,
  location,
}) => {
  const {
    allWpPost: { nodes: posts },
    wp,
  } = data;
  const { allWpProductAd } = ProductAdQuery();
  const { currentPage, numPages } = pageContext;

  const productAdIndex = getRandomInt(allWpProductAd.nodes.length);
  const productAd = allWpProductAd.nodes[productAdIndex];

  return (
    <DefaultPollenLayout pathname={location.pathname}>
      {wp.seo && (
        <Seo
          title={"Pollen Magazine by SimplePractice™"}
          postSchema={wp.seo.contentTypes.post.schema.raw}
          post={{
            seo: {
              metaRobotsNoindex:
                process.env.GATSBY_ENVIRONMENT === "dev" ? "noindex" : "",
              metaRobotsNofollow:
                process.env.GATSBY_ENVIRONMENT === "dev" ? "nofollow" : "",
            },
          }}
        />
      )}

      <PollenSectionContainer posts={posts} productAd={productAd} />

      <PostsNavSection
        parentUrl="/pollen/"
        currentPage={currentPage}
        hasNext={currentPage < numPages}
        hasPrevious={currentPage > 1}
      />
    </DefaultPollenLayout>
  );
};

export default DefaultPage;

export const POLLEN_PAGE_QUERY = graphql`
  query PostListQuery($skip: Int = 0, $limit: Int = 1000) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        id
        title
        excerpt
        content
        author {
          node {
            name
            uri
          }
        }
        uri
        featuredImage {
          node {
            altText
            gatsbyImage(
              width: 800
              placeholder: BLURRED
              formats: [WEBP, AUTO]
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`;
